.slide-in-down.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
    -webkit-transform: translateY(0);
    transform: translateY(0); }

.slide-in-left.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
    -webkit-transform: translateX(0);
    transform: translateX(0); }

.slide-in-up.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
    -webkit-transform: translateY(0);
    transform: translateY(0); }

.slide-in-right.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
    -webkit-transform: translateX(0);
    transform: translateX(0); }

.slide-out-down.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }

.slide-out-right.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }

.slide-out-up.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }

.slide-out-left.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }

.fade-in.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
    opacity: 1; }

.fade-out.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    opacity: 1;
    -webkit-transition-property: opacity;
    transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
    opacity: 0; }

.hinge-in-from-top.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateX(-90deg);
    transform: perspective(2000px) rotateX(-90deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    opacity: 1; }

.hinge-in-from-right.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateY(-90deg);
    transform: perspective(2000px) rotateY(-90deg);
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    opacity: 1; }

.hinge-in-from-bottom.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateX(90deg);
    transform: perspective(2000px) rotateX(90deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    opacity: 1; }

.hinge-in-from-left.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateY(90deg);
    transform: perspective(2000px) rotateY(90deg);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateX(-90deg);
    transform: perspective(2000px) rotateX(-90deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateY(-90deg);
    transform: perspective(2000px) rotateY(-90deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    opacity: 1; }

.hinge-out-from-top.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateX(90deg);
    transform: perspective(2000px) rotateX(90deg);
    opacity: 0; }

.hinge-out-from-right.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateY(90deg);
    transform: perspective(2000px) rotateY(90deg);
    opacity: 0; }

.hinge-out-from-bottom.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateX(-90deg);
    transform: perspective(2000px) rotateX(-90deg);
    opacity: 0; }

.hinge-out-from-left.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateY(-90deg);
    transform: perspective(2000px) rotateY(-90deg);
    opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateX(90deg);
    transform: perspective(2000px) rotateX(90deg);
    opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
    transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateY(90deg);
    transform: perspective(2000px) rotateY(90deg);
    opacity: 0; }

.scale-in-up.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }

.scale-in-down.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }

.scale-out-up.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; }

.scale-out-down.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; }

.spin-in.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: rotate(-0.75turn);
    transform: rotate(-0.75turn);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }

.spin-out.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
    -webkit-transform: rotate(0.75turn);
    transform: rotate(0.75turn);
    opacity: 0; }

.spin-in-ccw.mui-enter {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: rotate(0.75turn);
    transform: rotate(0.75turn);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }

.spin-out-ccw.mui-leave {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
    -webkit-transform: rotate(-0.75turn);
    transform: rotate(-0.75turn);
    opacity: 0; }

.slow {
    -webkit-transition-duration: 750ms !important;
    transition-duration: 750ms !important; }

.fast {
    -webkit-transition-duration: 250ms !important;
    transition-duration: 250ms !important; }

.linear {
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important; }

.ease {
    -webkit-transition-timing-function: ease !important;
    transition-timing-function: ease !important; }

.ease-in {
    -webkit-transition-timing-function: ease-in !important;
    transition-timing-function: ease-in !important; }

.ease-out {
    -webkit-transition-timing-function: ease-out !important;
    transition-timing-function: ease-out !important; }

.ease-in-out {
    -webkit-transition-timing-function: ease-in-out !important;
    transition-timing-function: ease-in-out !important; }

.bounce-in {
    -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
    transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
    -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
    transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
    -webkit-transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
    transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
    -webkit-transition-delay: 300ms !important;
    transition-delay: 300ms !important; }

.long-delay {
    -webkit-transition-delay: 700ms !important;
    transition-delay: 700ms !important; }

.shake {
    -webkit-animation-name: shake-7;
    animation-name: shake-7; }

@-webkit-keyframes shake-7 {
    0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
        -webkit-transform: translateX(7%);
        transform: translateX(7%); }
    5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
        -webkit-transform: translateX(-7%);
        transform: translateX(-7%); } }

@keyframes shake-7 {
    0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
        -webkit-transform: translateX(7%);
        transform: translateX(7%); }
    5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
        -webkit-transform: translateX(-7%);
        transform: translateX(-7%); } }

.spin-cw {
    -webkit-animation-name: spin-cw-1turn;
    animation-name: spin-cw-1turn; }

@-webkit-keyframes spin-cw-1turn {
    0% {
        -webkit-transform: rotate(-1turn);
        transform: rotate(-1turn); }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0); } }

@keyframes spin-cw-1turn {
    0% {
        -webkit-transform: rotate(-1turn);
        transform: rotate(-1turn); }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0); } }

.spin-ccw {
    -webkit-animation-name: spin-ccw-1turn;
    animation-name: spin-ccw-1turn; }

@-webkit-keyframes spin-ccw-1turn {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0); }
    100% {
        -webkit-transform: rotate(-1turn);
        transform: rotate(-1turn); } }

@keyframes spin-ccw-1turn {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0); }
    100% {
        -webkit-transform: rotate(-1turn);
        transform: rotate(-1turn); } }

.wiggle {
    -webkit-animation-name: wiggle-7deg;
    animation-name: wiggle-7deg; }

@-webkit-keyframes wiggle-7deg {
    40%, 50%, 60% {
        -webkit-transform: rotate(7deg);
        transform: rotate(7deg); }
    35%, 45%, 55%, 65% {
        -webkit-transform: rotate(-7deg);
        transform: rotate(-7deg); }
    0%, 30%, 70%, 100% {
        -webkit-transform: rotate(0);
        transform: rotate(0); } }

@keyframes wiggle-7deg {
    40%, 50%, 60% {
        -webkit-transform: rotate(7deg);
        transform: rotate(7deg); }
    35%, 45%, 55%, 65% {
        -webkit-transform: rotate(-7deg);
        transform: rotate(-7deg); }
    0%, 30%, 70%, 100% {
        -webkit-transform: rotate(0);
        transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms; }

.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }

.slow {
    -webkit-animation-duration: 750ms !important;
    animation-duration: 750ms !important; }

.fast {
    -webkit-animation-duration: 250ms !important;
    animation-duration: 250ms !important; }

.linear {
    -webkit-animation-timing-function: linear !important;
    animation-timing-function: linear !important; }

.ease {
    -webkit-animation-timing-function: ease !important;
    animation-timing-function: ease !important; }

.ease-in {
    -webkit-animation-timing-function: ease-in !important;
    animation-timing-function: ease-in !important; }

.ease-out {
    -webkit-animation-timing-function: ease-out !important;
    animation-timing-function: ease-out !important; }

.ease-in-out {
    -webkit-animation-timing-function: ease-in-out !important;
    animation-timing-function: ease-in-out !important; }

.bounce-in {
    -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
    animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
    -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
    animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
    -webkit-animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
    animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
    -webkit-animation-delay: 300ms !important;
    animation-delay: 300ms !important; }

.long-delay {
    -webkit-animation-delay: 700ms !important;
    animation-delay: 700ms !important; }
